.revibot-container {
    padding: 24px;
  }
  
  .revibot-card {
    background: var(--background-color);
    border: 1px solid var(--border-color);
  }
  
  .revibot-card .ant-card-head {
    border-bottom: 1px solid var(--border-color);
  }