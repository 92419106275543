.admin-backup {
  padding: 24px;
}

.backup-card {
  max-width: 800px;
  margin: 0 auto;
}

.ant-form-item {
  margin-bottom: 24px;
}

