@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .ProseMirror {
    @apply min-h-[300px] px-3 py-2 text-sm;
  }

  .ProseMirror:focus {
    @apply outline-none;
  }

  .ProseMirror > * + * {
    @apply mt-4;
  }

  .ProseMirror ul,
  .ProseMirror ol {
    @apply pl-4;
  }

  .ProseMirror ul {
    @apply list-disc;
  }

  .ProseMirror ol {
    @apply list-decimal;
  }

  .ProseMirror blockquote {
    @apply pl-4 border-l-4 border-muted italic;
  }

  .ProseMirror img {
    @apply max-w-full h-auto;
  }

  .ProseMirror hr {
    @apply my-4;
  }

  .ProseMirror h1 {
    @apply text-2xl font-bold;
  }

  .ProseMirror a {
    @apply text-primary underline;
  }
}