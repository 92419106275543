/* Layout styles */
.ant-layout {
  background-color: var(--background-color);
}

.ant-layout-sider {
  background-color: var(--gray-100);
  transition: all 0.2s;
  position: sticky;
  top: 64px;
  height: calc(100vh - 64px);
  overflow-y: auto;
}

.ant-layout-header {
  background-color: var(--background-color);
  border-bottom: 1px solid var(--gray-300);
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.ant-layout-content {
  background-color: var(--background-color);
  margin-top: 0;
  padding-top: 0;
}

/* Sidebar Menu styles */
.ant-menu {
  background-color: var(--gray-100);
  color: var(--text-color);
  border-right: none !important;
}

.ant-menu-item-selected {
  background-color: var(--primary-color-shade) !important;
  color: var(--primary-color) !important;
}

.ant-menu-item:hover {
  background-color: var(--gray-200) !important;
}

.ant-menu-item a {
  color: inherit !important;
  transition: color 0.3s ease !important;
}

.ant-menu-item-selected a {
  color: var(--primary-color) !important;
  font-weight: bold !important;
  transition: font-weight 0.3s ease !important;
}

.ant-menu-submenu:hover > .ant-menu-submenu-title {
  color: var(--text-color) !important;
}

.ant-menu-submenu-selected > .ant-menu-submenu-title {
  color: var(--primary-color) !important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  padding-left: 14px !important;
  text-align: left;
}

.ant-menu-item svg,
.ant-menu-submenu-title svg {
  font-size: 1.1rem !important;
}

.ant-menu-submenu-inline > .ant-menu-submenu-title:hover {
  background-color: var(--gray-200) !important;
}

.ant-menu-submenu-inline .ant-menu-sub {
  background-color: var(--gray-100) !important;
}

.ant-menu-submenu-inline .ant-menu-item:hover {
  background-color: var(--gray-200) !important;
}

.ant-menu-submenu-inline .ant-menu-item-selected {
  background-color: var(--primary-color-shade) !important;
}

.ant-menu-submenu-popup .ant-menu-sub {
  background-color: var(--gray-100) !important;
  border: 1px solid var(--gray-300) !important;
}

.ant-menu-submenu-popup .ant-menu-item {
  background-color: var(--gray-100);
}

.ant-menu-submenu-popup .ant-menu-item:hover {
  background-color: var(--gray-200) !important;
}

.ant-menu-submenu-popup .ant-menu-item-selected {
  background-color: var(--primary-color-shade) !important;
}


/* Table styles */
.ant-table-wrapper .ant-table-thead > tr > th {
  background-color: var(--gray-200);
  color: var(--text-color);
  border: 1px solid var(--gray-200);
  border-right: none; /* Remove vertical separators between header cells */
  border: none;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.ant-table-wrapper .ant-table-thead > tr > th::before {
  display: none !important; /* Remove the pseudo-element that creates the separator */
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: var(--gray-100);
  color: var(--text-color);
  border: none;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.ant-table-wrapper .ant-table-tbody > tr:hover > td {
  background-color: var(--gray-300) !important;
}

/* Card Styles */
.ant-card-head {
  background-color: var(--primary-color) !important; /* Not being applied? */
}

.ant-card-head-title {
  color: #F7FAFC !important;
}

.ant-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

/* Drawer Styles */
.ant-drawer-title {
  color: #F7FAFC !important;
}

/* Modal Styles */
.ant-modal-content,
.ant-modal-header {
  background-color: var(--background-color) !important;
  color: var(--text-color) !important;
}

.ant-modal-title {
  color: var(--text-color) !important;
}

.ant-modal-close-x {
  color: var(--text-color) !important;
}

/* Dropdown Styles */
.ant-dropdown-menu {
  background-color: var(--background-color) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

/* Select Styles */
.ant-select-dropdown {
  background-color: var(--background-color) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.ant-select-item-option-selected {
  background-color: var(--primary-color-shade) !important;
}

/* Avatar Styles */
.ant-avatar {
  border: none !important;
}

/* Select Styles */
.ant-select-dropdown {
  background-color: var(--background-color) !important;
  border: 1px solid var(--gray-300) !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--primary-color) !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: var(--gray-200) !important;
  color: var(--text-color) !important;
}

.multi-select-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.multi-select-actions .ant-btn {
  flex: 1;
}

.multi-select-actions .ant-btn:not(:last-child) {
  margin-right: 8px;
}

/* Dropdown Styles */
.ant-dropdown-menu {
  background-color: var(--background-color) !important;
  border: 1px solid var(--gray-300) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25) !important;
}

/* DatePicker Styles */
.ant-picker-dropdown {
  background-color: var(--background-color) !important;
  border: 1px solid var(--gray-300) !important;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
}

.ant-picker-panel-container {
  background-color: var(--gray-100) !important;
  border-radius: 10px !important;
}

.ant-picker-cell-in-view {
  color: var(--text-color) !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background-color: var(--gray-200) !important;
}

.ant-picker-cell-inner {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 4px !important;
}

.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: var(--primary-color) !important;
  color: #ffffff !important;
}

.ant-picker-footer,
.ant-picker-footer-extra,
.ant-picker-today-btn {
  background-color: var(--gray-200) !important;
}

.ant-picker-now-btn {
  color: var(--text-color) !important;
}
.ant-picker-now-btn:hover {
  color: var(--primary-color) !important;
}

/* Button Styles */

.ant-btn {
  color: var(--text-color) !important;
  border: 1px solid var(--gray-300) !important;
}

.ant-btn:hover {
  border-color: var(--primary-color) !important;
}

/* Tag Button Styles */
.ant-btn-link.tag-button {
  padding: 0;
  height: auto;
  line-height: 1;
  border: none !important;
}

.ant-btn-link.tag-button:hover,
.ant-btn-link.tag-button:focus {
  background: none;
}

.ant-btn-link.tag-button .ant-tag {
  margin-right: 0;
}

.ant-btn-link.tag-button:not(:last-child) .ant-tag {
  margin-right: 8px;
}

/* Drawer Styles */
.ant-drawer-header {
  background-color: var(--primary-color);
  border-bottom: 1px solid var(--gray-300);
}

.ant-drawer-close {
  color: #F7FAFC !important;
}

.ant-drawer-body {
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Tabs Styles */
.ant-tabs {
  margin-bottom: 0 !important;
}

.ant-tabs-content-holder {
  padding-top: 1rem;
}

/* Popconfirm Styles */
.ant-popover-content {
  border: 1px solid var(--gray-300) !important;
}

.ant-popover-inner {
  background-color: var(--background-color) !important;
}

.ant-popover-buttons {
  display: flex;
  justify-content: flex-end;
}

/* Confirm Modal Styles */
.ant-modal.ant-modal-confirm {
  background-color: var(--background-color);
}

.ant-modal-confirm .ant-modal-content {
  background-color: var(--background-color);
  border: 1px solid var(--gray-300);
  box-shadow: none !important;
}

.ant-modal-confirm-title {
  color: var(--text-color) !important;
}

.ant-modal-confirm-content {
  color: var(--text-color) !important;
}

/* Style the warning icon */
.ant-modal-confirm .anticon-exclamation-circle {
  color: var(--error-color) !important;
  font-size: 22px !important;
}

/* Style for the cancel button in confirm modals */
.ant-modal-confirm .ant-btn-default {
  background-color: var(--gray-200);
  border-color: var(--gray-300);
}

.ant-modal-confirm .ant-btn-default:hover {
  background-color: var(--gray-300) !important;
  border-color: var(--primary-color) !important;
}

/* Style for the confirm/danger button in confirm modals */
.ant-modal-confirm .ant-btn-primary {
  background-color: var(--error-color) !important;
  border-color: var(--error-color) !important;
  color: var(--text-color) !important;
}

.ant-modal-confirm .ant-btn-primary:hover {
  background-color: var(--background-color) !important;
  border-color: var(--error-color) !important;
  opacity: 0.9;
}