@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  * {
    @apply border-border; /* This will force the border to use our theme variable */
  }
  :root {
    --background: 0 0% 100%;
    --foreground: 20 14.3% 4.1%;
    --card: 0 0% 100%;
    --card-foreground: 20 14.3% 4.1%;
    --popover: 0 0% 100%;
    --popover-foreground: 20 14.3% 4.1%;
    --primary: 214 100% 50%;
    --primary-foreground: 0 0% 100%;
    --secondary: 60 4.8% 95.9%;
    --secondary-foreground: 24 9.8% 10%;
    --muted: 60 4.8% 95.9%;
    --muted-foreground: 25 5.3% 44.7%;
    --accent: 60 4.8% 95.9%;
    --accent-foreground: 24 9.8% 10%;
    --success: 142 72% 35%;
    --success-foreground: 0 0% 100%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 60 9.1% 97.8%;
    --border: 20 5.9% 90%;
    --input: 20 5.9% 90%;
    --ring: 24.6 95% 53.1%;
    --radius: 0.75rem;
    --empty-cell: 240 4.8% 95.9%;
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --timetracker-gradient-from: 0 100% 71%;
    --timetracker-gradient-to: 214 100% 50%;
    --digiai-gradient-from: 214 100% 50%;
    --digiai-gradient-to: 142 100% 50%;
    --badge-deal: 262 83% 58%;
    --badge-account: 214 100% 50%;
    --badge-contact: 142 72% 35%;
    --badge-product: 24.6 95% 53.1%;
    --chart-1: 24.6 95% 53.1%;
    --chart-2: 20.5 90.2% 48.2%;
    --chart-3: 15.3 86.2% 40.4%;
    --chart-4: 8.9 82.7% 31.6%;
    --chart-5: 5.8 80.2% 27.8%;
    --chart-6: 3.7 77.1% 25.1%;
    --chart-7: 2.7 74.8% 23.1%;
    --chart-8: 1.4 72.2% 20.8%;
    --chart-9: 0.7 71.4% 17.3%;
    --chart-multi-1: 221 83% 53%;
    --chart-multi-2: 142 71% 45%;
    --chart-multi-3: 24 95% 53%;
    --chart-multi-4: 262 83% 58%;
    --chart-multi-5: 0 84% 60%;
  }

  .dark {
    --background: 214 45% 12%;
    --foreground: 210 20% 98%;
    --card: 214 40% 16%;
    --card-foreground: 210 20% 98%;
    --popover: 214 35% 18%;
    --popover-foreground: 210 20% 98%;
    --primary: 214 100% 50%;
    --primary-foreground: 210 20% 98%;
    --secondary: 214 25% 20%;
    --secondary-foreground: 210 20% 98%;
    --muted: 214 25% 22%;
    --muted-foreground: 214 20% 75%;
    --accent: 214 35% 22%;
    --accent-foreground: 210 20% 98%;
    --success: 142 76% 45%;
    --success-foreground: 210 20% 98%;
    --destructive: 0 72.2% 50.6%;
    --destructive-foreground: 210 20% 98%;
    --border: 214 35% 20%;
    --input: 12 6.5% 15.1%;
    --ring: 214 100% 50%;
    --empty-cell: 214 25% 22%;
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
    --timetracker-gradient-from: 0 100% 71%;
    --timetracker-gradient-to: 214 100% 50%;
    --digiai-gradient-from: 214 100% 50%;
    --digiai-gradient-to: 142 100% 50%;
    --badge-deal: 262 83% 58%;
    --badge-account: 214 100% 50%;
    --badge-contact: 142 72% 35%;
    --badge-product: 24.6 95% 53.1%;
    --chart-1: 24.6 95% 53.1%;
    --chart-2: 20.5 90.2% 48.2%;
    --chart-3: 15.3 86.2% 40.4%;
    --chart-4: 8.9 82.7% 31.6%;
    --chart-5: 5.8 80.2% 27.8%;
    --chart-6: 3.7 77.1% 25.1%;
    --chart-7: 2.7 74.8% 23.1%;
    --chart-8: 1.4 72.2% 20.8%;
    --chart-9: 0.7 71.4% 17.3%;
    --chart-multi-1: 221 83% 53%;
    --chart-multi-2: 142 71% 45%;
    --chart-multi-3: 24 95% 53%;
    --chart-multi-4: 262 83% 58%;
    --chart-multi-5: 0 84% 60%;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
