/* General Layout */
.invoice-dashboard {
  padding: var(--spacing-large);
  margin: 0 auto;
}

.invoice-container {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-large);
}

.main-content {
  flex: 2;
  min-width: 300px;
}

.invoice-sidebar {
  flex: 1;
  min-width: 450px;
  max-width: 550px;
}

.invoice-container,
.main-content,
.invoice-sidebar {
  background-color: transparent;
}

/* Loading */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Dashboard Controls */
.dashboard-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--spacing-large);
}

.dashboard-controls .ant-picker {
  margin: 0 var(--spacing-medium);
}

.date-type-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: var(--spacing-medium);
}

.date-type-buttons .ant-btn {
  margin: 0 var(--spacing-small);
}

/* Totals Card */
.totals-card {
  margin-bottom: var(--spacing-large);
}

.totals-card .ant-card-head {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.totals-card .ant-card-body {
  padding: var(--spacing-medium);
}

.totals-card .ant-col .ant-card {
  height: 100%;
  text-align: center;
}

.total-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.total-title {
  font-size: var(--font-size-normal);
  margin-bottom: var(--spacing-small);
  text-align: center;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.total-amount {
  font-size: var(--font-size-large);
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
}

/* Monthly Totals Card */
.monthly-totals-card {
  margin-bottom: var(--spacing-large);
}

.monthly-totals-card .ant-card-head {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.monthly-totals-card .ant-card-body {
  padding: var(--spacing-medium);
}

.monthly-totals-card .chart-container {
  max-width: 800px;
  margin: 0 auto;
}

.chart-wrapper {
  height: 100%;
}

.chart-container {
  height: 200px;
  cursor: pointer;
}

/* Expanded Chart */
.expanded-chart-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.expanded-chart-container {
  background-color: white;
  padding: var(--spacing-large);
  border-radius: 8px;
  width: 80%;
  max-width: 1000px;
  height: 80%;
}

.expanded-chart-container .chart-container {
  height: 100%;
  cursor: default;
}

/* Period Card */
.period-card {
  margin-bottom: var(--spacing-large);
}

.period-card .ant-card-head {
  background-color: var(--primary-color);
  color: var(--background-color);
}

.period-card .ant-card-body {
  padding: var(--spacing-medium);
}

.period-card .ant-col .ant-card {
  height: 100%;
  text-align: center;
}

.period-card .ant-col .ant-card .card-title {
  font-size: var(--font-size-normal);
  margin-bottom: var(--spacing-small);
  font-weight: bold;
}

.period-card .ant-col .ant-card .card-value {
  font-size: var(--font-size-large);
  margin-bottom: var(--spacing-small);
  font-weight: bold;
}

/* Due Date Explainer */
.due-date-explainer {
  display: flex;
  justify-content: flex-end;
  margin-top: var(--spacing-large);
  font-size: var(--font-size-small);
}

.explainer-item {
  display: flex;
  align-items: center;
  margin-left: var(--spacing-medium);
}

.color-box {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: var(--spacing-small);
  border: 1px solid var(--border-color);
}

.color-box.past-due {
  background-color: var(--error-color);
}

.color-box.due-soon {
  background-color: var(--warning-color);
}

.past-due {
  color: var(--error-color);
}

.due-soon {
  color: var(--warning-color);
}

/* Invoice Table */
.invoice-table {
  margin-top: var(--spacing-large);
}

.invoice-table .ant-table-footer {
  font-weight: bold;
}

/* AI Analysis Card */
.ai-analysis-container {
  width: 100%;
  max-width: 1200px;
  margin: var(--spacing-large) auto 0;
}

.ai-analysis-card {
  margin-top: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.ai-analysis-card .ant-card-head {
  background: linear-gradient(135deg, var(--primary-color), #00FF7F);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ai-analysis-card .ant-card-head-title {
  color: white;
  font-weight: bold;
}

.ai-analysis-content {
  padding: 16px;
}

.ai-analysis-content h2 {
  font-size: 1.4em;
  margin-top: 20px;
  margin-bottom: 12px;
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding-bottom: 5px;
}

.ai-analysis-content h3 {
  font-size: 1.2em;
  margin-top: 16px;
  margin-bottom: 8px;
  color: var(--text-color);
}

.ai-analysis-content ul {
  padding-left: 20px;
  margin-bottom: 16px;
}

.ai-analysis-content li {
  margin-bottom: 8px;
}

.ai-analysis-content strong {
  color: var(--primary-color);
}

/* Utility Classes */
.card-divider {
  height: 1px;
  width: 60%;
  margin: var(--spacing-small) auto;
  background-color: var(--info-color);
}

.no-data-message {
  text-align: center;
  font-size: var(--font-size-large);
  margin: var(--spacing-large) 0;
}

/* Media Queries */
@media (max-width: 1400px) {
  .invoice-container {
    flex-direction: column;
  }

  .invoice-sidebar {
    width: 100%;
    max-width: none;
  }

  .chart-container {
    height: 300px;
  }

  .ai-analysis-container {
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
  .invoice-container {
    flex-direction: column;
  }

  .invoice-sidebar {
    width: 100%;
  }

  .chart-container {
    height: 300px;
  }
}

@media (max-width: 768px) {
  .invoice-dashboard {
    padding: var(--spacing-medium);
  }

  .chart-container {
    height: 300px; /* Increased height for better visibility */
  }

  .expanded-chart-container {
    width: 95%;
    height: 60%;
  }

  .monthly-totals-card .chart-container {
    max-width: 100%;
  }

  .ai-analysis-card {
    margin-top: 16px;
  }

  .ai-analysis-content {
    padding: 12px;
  }

  .ai-analysis-content h2 {
    font-size: 1.3em;
  }

  .ai-analysis-content h3 {
    font-size: 1.1em;
  }
}

@media (max-width: 768px) {
  .totals-card .ant-col,
  .period-card .ant-col {
    width: 50%;
    margin-bottom: var(--spacing-medium);
  }
}

@media (max-width: 480px) {
  .totals-card .ant-col,
  .period-card .ant-col {
    width: 100%;
  }
}