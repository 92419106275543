.dashboard-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.dashboard-content {
  flex: 1;
  padding: var(--spacing-medium);
  background-color: var(--background-color);
  color: var(--text-color);
}

.digi-dashboard-logo {
  width: 300px;
  object-fit: contain;
  max-height: 60px;
}

.dashboard-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--spacing-medium);
  padding: var(--spacing-small);
  background-color: var(--color-background-secondary);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-light);
}

.date-type-selector {
  margin-bottom: var(--spacing-small);
}

.date-picker-container {
  display: flex;
  align-items: center;
}

.date-picker-container .ant-picker {
  margin: 0 var(--spacing-small);
}

.ant-tabs-content {
  flex-grow: 1;
  overflow-y: auto;
}

/* Custom scrollbar for webkit browsers */
.ant-tabs-content::-webkit-scrollbar {
  width: 8px;
}

.ant-tabs-content::-webkit-scrollbar-track {
  background: var(--gray-200);
  border-radius: 4px;
}

.ant-tabs-content::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 4px;
}

.ant-tabs-content::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-shade);
}

.refresh-button {
  margin-top: var(--spacing-small);
}

/* Responsive design */
@media (max-width: 768px) {
  .digi-dashboard-logo {
    max-width: 150px;
  }

  .dashboard-controls {
    flex-direction: column;
    align-items: stretch;
  }

  .date-picker-container {
    flex-direction: column;
  }

  .date-picker-container .ant-picker {
    margin: var(--spacing-small) 0;
  }
}