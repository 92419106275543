.admin-monitor {
    padding: 24px;
  }
  
  .redis-metrics {
    margin-top: 16px;
  }
  
  .redis-metrics .ant-card {
    height: 100%;
  }
  
  .redis-metrics .ant-statistic {
    margin-bottom: 16px;
  }
  
  .redis-metrics .ant-progress {
    margin-top: 16px;
  }